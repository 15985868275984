export default {
  search: [
    { name: '供应商', type: 'text', fieldName: 'keyword', value: '' },
    { name: '联系人名字或电话', type: 'text', fieldName: 'contact_keyword', value: '', width: '200px' },
    {
      fieldName: 'brand_type',
      constantDict: 'brand_type',
      type: 'select',
      name: '品牌类型',
      required: true,
      value: '',
      options: [],
    },
    {
      name: '品牌',
      type: 'select',
      fieldName: 'brand_id',
      value: '',
      remote: true,
      multiple: true,
      collapseTags: true,
      width: '180px',
      props: {
        url: '/brand/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      fieldName: 'cate_id',
      type: 'treeSelect',
      name: '品类',
      value: [],
      options: [],
      remote: true,
      props: {
        url: '/cate/list',
        value: 'id',
        label: 'name',
        params: { type: 1 },
      },
    },
    {
      name: '用途',
      type: 'select',
      fieldName: 'purpose_id',
      value: '',
      remote: true,
      props: {
        url: '/purpose/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '价格区间',
      type: 'select',
      fieldName: 'price_range_id',
      value: '',
      options: [],
      remote: true,
      props: {
        url: '/price-range/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '场景',
      type: 'select',
      fieldName: 'scene_id',
      value: '',
      remote: true,
      props: {
        url: '/scene/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '城市',
      type: 'cascader',
      fieldName: 'contact_province_id||contact_city_id',
      value: [],
      options: [],
      remote: true,
      props: {
        url: '/common/area',
        value: 'value',
        label: 'label',
        params: { level: 2 },
      },
    },
  ],
  form: [
    { fieldName: 'name', type: 'text', name: '供应商名称', value: '', required: true },
    {
      fieldName: 'status',
      type: 'select',
      name: '状态',
      required: true,
      value: '',
      options: [
        { label: '启用', value: 0 },
        { label: '禁用', value: 1 },
      ],
    },
    {
      name: '所在城市',
      type: 'cascader',
      fieldName: 'contact_province_id||contact_city_id||contact_area_id',
      value: [],
      options: [],
      remote: true,
      required: true,
      width: '55%',
      props: {
        url: '/common/area',
        value: 'value',
        label: 'label',
        params: { level: 3 },
        checkStrictly: true,
      },
    },
    { fieldName: 'contact_address', type: 'text', name: '详细地址', value: '', required: false, width: '45%' },
    {
      name: '仓库所在城市',
      type: 'cascader',
      fieldName: 'ware_province_id||ware_city_id||ware_area_id',
      value: [],
      options: [],
      remote: true,
      required: false,
      width: '55%',
      props: {
        url: '/common/area',
        value: 'value',
        label: 'label',
        params: { level: 3 },
        checkStrictly: true,
      },
    },
    { fieldName: 'ware_address', type: 'text', name: '仓库详细地址', value: '', required: false, width: '45%' },
    {
      name: '品牌',
      type: 'select',
      fieldName: 'brand_ids',
      value: [],
      options: [],
      remote: true,
      required: true,
      width: '100%',
      multiple: true,
      props: {
        url: '/brand/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      fieldName: 'cate_ids',
      type: 'treeSelect',
      name: '品类',
      value: [],
      options: [],
      required: true,
      remote: true,
      collapseTags: true,
      width: '100%',
      props: {
        url: '/cate/list',
        value: 'id',
        label: 'name',
        params: { type: 1 },
      },
    },
    {
      name: '产品用途',
      type: 'select',
      fieldName: 'purpose_ids',
      value: [],
      options: [],
      remote: true,
      required: true,
      width: '100%',
      multiple: true,
      props: {
        url: '/purpose/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '价格区间',
      type: 'select',
      fieldName: 'price_range_ids',
      value: [],
      options: [],
      remote: true,
      required: true,
      width: '100%',
      multiple: true,
      props: {
        url: '/price-range/list',
        value: 'id',
        label: 'name',
      },
    },
    {
      name: '应用场景',
      type: 'select',
      fieldName: 'scene_ids',
      value: [],
      options: [],
      remote: true,
      required: true,
      width: '100%',
      multiple: true,
      props: {
        url: '/scene/list',
        value: 'id',
        label: 'name',
      },
    },
    { fieldName: 'memo', type: 'textarea', name: '备注', value: '', required: false, width: '100%' },
    {
      fieldName: 'attachment_ids',
      responseName: 'attachment',
      type: 'upload',
      name: '附件',
      value: [],
      multiple: true,
      required: false,
      width: '100%',
    },
    { fieldName: 'id', type: 'text', name: 'id', value: '', hide: true },
  ],
};
