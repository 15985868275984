<template>
  <div>
    <lz-card
      title="联系人详情">
      <el-button slot="right" type="primary" @click="handleCommonBtnCLick('add')">新增</el-button>
      <lz-table-list
        :table="table"
        @table-btn-click="handleCommonBtnCLick"
        :list="list">
      </lz-table-list>
    </lz-card>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :width="500"
      title="联系人详细信息"
      ref="lzDialog">
      <lz-form :form="form" :col="1" ref="lzForm"></lz-form>
    </lz-dialog>
  </div>
</template>
<script>
export default {
  props: {
    list: {
      default: [],
    },
  },
  watch: {
    list: function change() {
      this.list.forEach((el) => {
        this.$set(el, 'htmlButton', [
          { name: '查看', key: 'detail', type: 'primary' },
          { name: '删除', key: 'delete', type: 'danger' },
        ]);
      });
    },
  },
  data() {
    return {
      index: '',
      form: [
        { fieldName: 'name', type: 'text', name: '名称', value: '', required: true },
        { fieldName: 'phone', type: 'text', name: '电话', value: '', required: true },
        { fieldName: 'title', type: 'text', name: '职位', value: '', required: true },
        { fieldName: 'email', type: 'text', name: '邮箱', value: '', required: false },
        { fieldName: 'wechat', type: 'text', name: '微信', value: '', required: false },
        { fieldName: 'qq', type: 'text', name: 'QQ', value: '', required: false },
      ],
      table: [
        { name: '联系人', prop: 'name' },
        { name: '联系电话', prop: 'phone' },
        { name: '邮箱', prop: 'email' },
        { name: '职位', prop: 'title' },
        { name: '操作', prop: 'htmlButton' },
      ],
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
    };
  },
  methods: {
    handleCommonBtnCLick(type, element, index) {
      switch (type) {
        case 'detail':
          this.index = index;
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              this.list.splice(index, 1);
            },
          });
          break;
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          if (this.index === '') {
            this.list.push(params);
          } else {
            this.$set(this.list, this.index, params);
          }
          this.$message.success('操作成功');
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.index = '';
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(async () => {
        this.$refs.lzForm.setValue({
          data: response || {},
        });
      });
    },
  },
};
</script>
