<template>
  <div>
    <lz-list
      :config="config"
      :table="table"
      :search="search"
      ref="lzList"
      :default-expand-all="false"
      table-height="97%"
      @search-btn-click="handleCommonBtnCLick"
      @table-btn-click="handleTableBtnClick">
    </lz-list>
    <lz-dialog
      :dialogBtns="dialogBtns"
      @dialog-btn-click="handleCommonBtnCLick"
      :title="title"
      ref="lzDialog">
      <lz-form :form="form" :col="3" ref="lzForm"></lz-form>
      <contact :list="contactList"></contact>
    </lz-dialog>
  </div>
</template>
<script>
import contact from './contact.vue';
import cfg from './config';

export default {
  components: {
    contact,
  },
  data() {
    return {
      search: JSON.parse(JSON.stringify(cfg.search)),
      config: {
        url: '/supplier/list',
      },
      table: [
        {
          type: 'expand',
          prop: 'expand',
          func: (ops) => {
            let str = '';
            ops.contact.forEach((r) => {
              str += `<div data-flex="" style="margin-bottom: 10px;font-size: 14px;">
                <div><span class="mr-10" style="color: #99a9bf">联系人:</span>${r.name}</div>
                <div class="ml-20"><span class="mr-10" style="color: #99a9bf">电话:</span>${r.phone}</div>
                <div class="ml-20"><span class="mr-10" style="color: #99a9bf">职位:</span>${r.title}</div>
                <div class="ml-20"><span class="mr-10" style="color: #99a9bf">邮箱:</span>${r.email || '-'}</div>
                <div class="ml-20"><span class="mr-10" style="color: #99a9bf">微信:</span>${r.wechat || '-'}</div>
                <div class="ml-20"><span class="mr-10" style="color: #99a9bf">QQ:</span>${r.qq || '-'}</div>
              </div>`;
            });
            return str;
          },
        },
        { name: 'ID', prop: 'id', width: '80px', align: 'center' },
        { name: '名称', prop: 'name', width: '200px' },
        {
          name: '品牌',
          width: '200px',
          func: (ops) => ops.brand_names.toString(),
        },
        {
          name: '品类',
          width: '200px',
          func: (ops) => ops.cate_names.toString(),
        },
        {
          name: '用途',
          width: '200px',
          func: (ops) => ops.purpose_names.toString(),
        },
        {
          name: '价格',
          width: '200px',
          func: (ops) => ops.price_range_names.toString(),
        },
        {
          name: '应用场景',
          width: '200px',
          func: (ops) => ops.scene_names.toString(),
        },
        { name: '操作', prop: 'htmlButton', fixed: 'right' },
      ],
      form: cfg.form,
      dialogBtns: [
        { key: 'save', name: '保存', type: 'primary' },
      ],
      title: '',
      contactList: [],
    };
  },
  methods: {
    handleTableBtnClick(type, element) {
      const lzList = this.$refs.lzList;
      switch (type) {
        case 'edit':
          this.handleDialogForm(element);
          break;
        case 'delete':
          this.$baseConfirm({
            content: '是否确定删除',
            callConfirm: async () => {
              const response = await this.$baseHttp.delete(`/supplier/delete?ids=${element.id}`);
              if (!response) return;
              this.$message.success('删除成功');
              lzList.getData();
            },
          });
          break;
        case 'user':
          this.$refs.user.init(element.id);
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    async handleCommonBtnCLick(type) {
      switch (type) {
        case 'save': {
          const params = this.$refs.lzForm.getValue();
          if (!params) return;
          params.contact = this.contactList;
          params.contact.forEach((el) => {
            delete el.htmlButton; // eslint-disable-line
          });
          const response = await this.$baseHttp.post('/supplier/save', params);
          if (!response) return;
          this.$message.success('操作成功');
          this.$refs.lzList.getData();
          this.$refs.lzDialog.dialogVisible = false;
        }
          break;
        case 'add':
          this.handleDialogForm();
          break;
        default:
          this.$message.warning('事件还未处理');
          break;
      }
    },
    handleDialogForm(response = null) {
      this.title = `${response ? '编辑' : '新增'}${this.$route.meta.title}`;
      this.$refs.lzDialog.dialogVisible = true;
      this.$nextTick(async () => {
        const params = {};
        if (response) {
          params.url = `/supplier/detail?id=${response.id}`;
        } else {
          params.data = {};
        }
        await this.$refs.lzForm.setValue(params);
        this.contactList = response ? response.contact : [];
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .contact div {
  margin-bottom: 10px;

  span {
    color: #99a9bf;
  }
}
</style>
